import React from 'react'
import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'
import themeObject from '../theme'
import { GlobalStyles } from '../theme/global'
import ReactPlayer from 'react-player'

export const Content = styled.main`
    display: block;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
    background-color: #000000;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`;

const VideoPage = () => {

    return (
        <ThemeProvider theme={themeObject}>
            <GlobalStyles />

            <Helmet
                htmlAttributes={{
                    lang: 'cs',
                    amp: undefined
                }}
                title='Inverze 89 - Volání - Radek Chmel'
            >
                <meta http-equiv="Pragma" content="no-cache" />
                <meta http-equiv="Expires" content="-1" />
                <meta http-equiv="CACHE-CONTROL" content="NO-CACHE, no-store, must-revalidate" />
                <meta name="robots" content="all,follow" />
                <meta name='googlebot' content='index,follow,snippet,archive' />

                <meta name='description' content='Nová fotografická publikace mapuje ekologické demonstrace v listopadu 1989 a návazné sametové dění v Teplicích.' />
                <meta name='keywords' content='Teplice, 1989, Ekologické demonstrace, Iverze, Kniha, Fotografie, Smog, Revoluce, 30 let' />
            </Helmet>

            <Content>
                <ReactPlayer
                    url='https://firebasestorage.googleapis.com/v0/b/inverze-89.appspot.com/o/audios%2FVolani_Radek_Chmel.mp3?alt=media&token=4c7dec56-198b-491d-8e42-2e2258c3d2fd'
                    playing={true}
                    controls={true}
                    config={{
                        file: {
                            attributes: {
                                controlslist: "nodownload"
                            }
                        }
                    }}
                />
            </Content>
        </ThemeProvider>
    );
}

export default VideoPage;
